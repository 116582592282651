import React, { useState, useEffect } from 'react';
import { createGuest, getGuests } from '../ApiClient';
import { PhoneIcon } from '@heroicons/react/24/solid';
const RSVPFormSection = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [guests, setGuests] = useState([]);

  // Obtener lista de invitados cuando el componente se monta
  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const guestList = await getGuests();
        setGuests(guestList);
      } catch (error) {
        console.error('Error al obtener invitados:', error);
      }
    };

    fetchGuests();
  }, []);

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Llama a la función `createGuest` para crear un invitado en el backend
      const newGuest = await createGuest({ name: `${firstName} ${lastName}` });

      // Agrega el invitado a la lista de confirmados en el estado
      setGuests([...guests, newGuest]);
      
      // Resetea los campos de entrada
      setFirstName('');
      setLastName('');
    } catch (error) {
      console.error('Error al crear el invitado:', error);
    }
  };

  return (
    <div className="bg-gradient-to-b from-pink-100 via-white to-pink-50 p-6 rounded-xl shadow-lg w-full text-center">
      {/* Título */}
      <h1 className="text-3xl font-bold text-pink-500 mb-4">
        Confirma tu Asistencia
      </h1>
      
      {/* Descripción */}
      <p className="text-md text-gray-700 mb-6">
        Queremos compartir este día especial con ustedes. Por favor, confirma tu asistencia completando el siguiente formulario.
      </p>

      {/* Formulario */}
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
          {/* Campo Nombre */}
          <input
            type="text"
            placeholder="Nombre"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="p-2 border-2 border-pink-300 rounded-md w-full sm:w-1/3"
            required
          />

          {/* Campo Apellido */}
          <input
            type="text"
            placeholder="Apellido"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="p-2 border-2 border-pink-300 rounded-md w-full sm:w-1/3"
            required
          />

          {/* Botón de Confirmar */}
          <button
            type="submit"
            className="w-full sm:w-auto bg-pink-500 text-white text-lg sm:text-base px-6 sm:px-4 py-3 sm:py-2 rounded-md hover:bg-pink-400 transition transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-pink-300"
          >
            Confirmar
          </button>
        </div>
      </form>

      {/* Lista de Invitados Confirmados */}
{/* Lista de Invitados Confirmados */}
{guests.length > 0 && (
  <div className="bg-gradient-to-r from-pink-200 via-pink-100 to-pink-50 p-4 sm:p-6 rounded-lg shadow-lg w-full">

    
    <h2 className="text-xl sm:text-2xl font-bold text-pink-500 mb-4 text-center">
      Lista de Invitados Confirmados
    </h2>

        {/* Contador de invitados */}
        <h2 className="text-lg sm:text-xl font-semibold text-pink-600 mb-2 text-center">
      Total Confirmados: {guests.length}
    </h2>

    <ul className="divide-y divide-pink-300">
      {guests.map((guest, index) => (
        <li
          key={index}
          className="py-3 flex items-center bg-white shadow-sm rounded-lg px-4 my-2 hover:shadow-lg transition-shadow duration-300 w-full"
        >
          {/* Avatar y Nombre */}
          <div className="flex items-center space-x-4 w-full">
            {/* Avatar Placeholder */}
            <div className="bg-pink-300 w-10 h-10 flex items-center justify-center rounded-full text-white font-semibold">
              {guest.name.charAt(0)}
            </div>
            {/* Nombre del Invitado */}
            <span className="text-gray-800 font-medium text-lg">{guest.name}</span>
          </div>
        </li>
      ))}
    </ul>
  </div>
)}
   {/* Sección de Anuncio */}
<div className="bg-gradient-to-r from-pink-200 to-pink-100 p-4 rounded-lg shadow-md text-pink-600 text-center mt-6">
  <p className="text-sm sm:text-base mb-4">
    ¿Te gustaría una invitación profesional?
  </p>
  <a 
    href="https://wa.me/528117450317" 
    target="_blank" 
    rel="noopener noreferrer"
    className="flex items-center justify-center gap-2 text-sm sm:text-base font-semibold bg-white text-pink-500 px-4 py-2 rounded-full shadow hover:bg-pink-200 transition"
  >
    <PhoneIcon className="h-5 w-5 text-pink-500" />
    +52 811 745 0317
  </a>
</div>
    </div>
  );
};

export default RSVPFormSection;