import axios from 'axios';


// para desarrolo quiero usar (http://localhost:3001)
// para produccion quiero usar (https://francolemon.duckdns.org/api_babyshowerastrid)
// Configuración base de Axios
const apiClient = axios.create({
  baseURL: 'https://api-babyshower.francolemon.duckdns.org', // Cambia la URL base según sea necesario
  headers: {
    'Content-Type': 'application/json',
  },
});

// Función para crear un nuevo invitado (POST)
export const createGuest = async (guestData) => {
  try {
    const response = await apiClient.post('/guests', { guest: guestData });
    return response.data;
  } catch (error) {
    console.error('Error creando invitado:', error);
    throw error;
  }
};

// Función para obtener la lista de invitados (GET)
export const getGuests = async () => {
  try {
    const response = await apiClient.get('/guests');
    return response.data;
  } catch (error) {
    console.error('Error obteniendo lista de invitados:', error);
    throw error;
  }
};