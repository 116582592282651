import React from 'react';
import image from '../images/astrid_mensaje.png';

const GiftsSection = () => {
  return (
    <div className="relative w-full h-screen">
      <img 
        src={image} 
        alt="Gifts Section"
        className="w-full h-full object-cover object-center"
      />
    </div>
  );
};

export default GiftsSection;