import React from "react";
import Invitation from "./Components/Invitacion";
import ParentsSection from "./Components/Padres";
import BabyPresentation from "./Components/Bebe";
import LocationDetails from "./Components/Ubicacion";
import GiftsSection from "./Components/Regalos";
import RSVPFormSection from "./Components/Formulario";



function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Invocando el componente Invitation */}
        <Invitation />
        <GiftsSection />
        <LocationDetails />

        <RSVPFormSection />
      </header>
    </div>
  );
}

export default App;