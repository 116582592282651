import React from 'react';

const LocationDetails = () => {
  return (
    <div className="bg-gradient-to-b from-pink-100 via-white to-pink-50 p-8 rounded-2xl shadow-xl w-full max-w-lg mx-auto text-center">
      {/* Título */}
      <h1 className="text-4xl font-bold text-pink-600 mb-6">
        Detalles del Evento
      </h1>
      
      {/* Mapa */}
      <div className="mb-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3594.4248718355907!2d-100.39567658981778!3d25.723459610091318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866296fdc08e7893%3A0x5d230a2072358ae0!2sP.%C2%BA%20de%20Las%20Estrellas%20108%2C%20Cumbres%205o.%20Sector%20Secc%20B%2C%2064610%20Monterrey%2C%20N.L.!5e0!3m2!1ses-419!2smx!4v1727810219546!5m2!1ses-419!2smx"
          width="100%"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa del Evento"
          className="rounded-lg shadow-lg"
        ></iframe>
      </div>

      {/* Botón para Abrir en Maps */}
      <div className="mb-8">
        <a
          href="https://www.google.com/maps/search/?api=1&query=Paseo+de+Las+Estrellas+108,+Cumbres+5o+Sector,+Monterrey"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-pink-600 text-white px-6 py-2 rounded-full shadow-md hover:bg-pink-700 transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-pink-300"
        >
          Abrir en Maps
        </a>
      </div>
      
      <div className="text-left">
  {/* Descripción del Evento */}
  <div className="text-lg text-gray-800 mb-6">
    <ul className="list-none space-y-2">
      <li className="flex items-center">
        📅 <span className="ml-2 font-normal">Jueves, 14 de noviembre de 2024</span>
      </li>
      <li className="flex items-center">
        🕒 <span className="ml-2 font-normal">3:00 PM</span>
      </li>
      <li className="flex items-center">
        📍 <span className="ml-2 font-normal">Ave Leones y Paseo de las Estrellas 108, Cumbres 5o Sector</span>
      </li>
    </ul>
  </div>
  
  {/* Información Adicional */}
  <div className="text-md text-gray-600 mb-4">
    <ul className="list-none space-y-1">
      <li className="flex items-center">
        🚪 <span className="ml-2">Entrada por la puerta principal.</span>
      </li>
      <li className="flex items-center">
        🅿️ <span className="ml-2">Estacionamiento disponible al frente del salón.</span>
      </li>
    </ul>
  </div>

  {/* Nota de No Niños y Solo Mujeres */}
  <div className="text-md text-red-600 font-semibold">
    <ul className="list-none space-y-1">
      <li className="flex items-center">
        ❌ <span className="ml-2">No niños</span>
      </li>
      <li className="flex items-center">
        👩 <span className="ml-2">Solo mujeres.</span>
      </li>
    </ul>
  </div>
</div>
      
    </div>
  );
};

export default LocationDetails;